import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";

import axios from "axios";

const QrResults = (props) => {
  const [contact, setContact] = useState("");
  // const [delivery, setDelivery] = useState(false);
 
  let displayText = "";

  if (props.result.urls === undefined || props.result.urls.length === 0) {
    displayText = "Wir haben leider keine Ergebnisse in Ihrem QR-Code gefunden. Bitte scannen Sie den QR-Code oben rechts auf dem Rezept.";
  } else if (props.result.urls.length === 1) {
    displayText = "Sie haben 1 Medikament gescannt. Sind auf Ihrem Rezept mehr Medikamente, scannen Sie den QR-Code rechts oben auf dem Rezept.";
  } else {
    displayText = "Sie haben " + props.result.urls.length + " Medikamente gescannt."
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log('submit clicked');
    axios
      .post('https://scanner.onworks-werbeagentur.de/api/send_mail.php', {
        mail: props.mail,
        result: props.result,
        contact: contact
      })
      .then((response) => {     
        alert('Vielen Dank. Ihr Rezept wurde erfolgreich an die Apotheke übermittelt.')
      });
    props.setResult({});
  }

  const handleCancel = (e) => {
    e.preventDefault();
    // console.log('cancel clicked');
    props.setResult({});
  }

  return (
    <>
      <div className="eRezeptResult">
        <h3>Scan erfolgreich!</h3>
        <p>{displayText}</p>
        <div className="resultForm">
          <form>
            {/* <Form.Check
              type="checkbox"
              id="lieferdienst"
              label="Lieferung per Botendienst"
              name="lieferart"
              value={delivery}
            />
            <br/> */}
            <Form.Label>Wie können wir Sie erreichen?</Form.Label>
            <Form.Control 
              size="md" 
              type="text" 
              value={contact}
              onChange={ (e) => setContact(e.target.value)} 
              name="contact"
              placeholder="E-Mail oder Telefon" 
              />
            <br/>
            <Button onClick={handleCancel} variant="secondary">Erneut Scannen</Button>
            { contact !== "" ?
              <Button 
                onClick={handleSubmit} 
                variant="danger"
                disabled={ contact === "" ? true : false}
                >Abschicken</Button> : undefined }
            { contact === "" ? <p style={{color: 'white', fontSize: "12px", fontWeight: 'bold', backgroundColor: 'red', padding: '5px', borderRadius: '5px'}}>Zum Abschicken E-Mail / Telefonnummer eingeben!</p> : undefined }
          </form>
        </div>
      </div>
    </>
    
  )
}

export default QrResults;